import { template as template_4430ec36cc984f6cbd70c987eaf36b91 } from "@ember/template-compiler";
const FKLabel = template_4430ec36cc984f6cbd70c987eaf36b91(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
