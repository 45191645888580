import { template as template_1e156ab7f430470bbf95e13efff7280a } from "@ember/template-compiler";
const FKControlMenuContainer = template_1e156ab7f430470bbf95e13efff7280a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
