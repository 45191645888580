import { template as template_286025de68bd4de2a10dd064392e4db2 } from "@ember/template-compiler";
const WelcomeHeader = template_286025de68bd4de2a10dd064392e4db2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
